<mat-toolbar class="num-footer" role="contentinfo">
  <mat-toolbar-row>
    <div>
      <img src="assets/img/bmbf-gef-mit.png" alt="BMBF-LOGO" class="bmbf-logo" />
      <img id="footer-logo" src="{{ urlSrc }}" alt="{{ urlAlt }}" class="{{ stylesheet }}" />
      <!--<img src="assets/img/NUM_Logo.png" alt="NUM-LOGO" class="num-logo" />-->
    </div>

    <span fxFlex></span>
    <div>
      <mat-toolbar-row>
        <a mat-button href="https://forschen-fuer-gesundheit.de/impressum.php">{{
          'LEGAL.DISCLOSURE' | translate
        }}</a>
        <mat-divider [vertical]="true"></mat-divider>
        <a mat-button href="https://forschen-fuer-gesundheit.de/nutzungsbedingungen.php#datenschutz"
          >{{ 'LEGAL.DATAPROTECTION' | translate }}
        </a>
        <mat-divider [vertical]="true"></mat-divider>
        <a mat-button href="mailto:info@forschen-fuer-gesundheit.de"
          >{{ 'LEGAL.CONTACT' | translate }}
        </a>
      </mat-toolbar-row>
      <div class="version">Version {{ config.legal.version }}</div>
    </div>
    <span fxFlex></span>
    <div>
      <div class="copyright">
        © {{ config.legal.copyrightYear }} {{ config.legal.copyrightOwner }}
      </div>
      <num-language></num-language>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
