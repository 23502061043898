<num-snackbar></num-snackbar>
<mat-toolbar fxLayout="row" fxLayoutAlign="start center" color="light" fxLayoutGap="0px">
  <img id="header-logo" src="{{ urlSrc }}" alt="{{ urlAlt }}" class="{{ stylesheet }}" />
  <div fxFlex="100" fxLayoutAlign="end" class="margin-right">
    <num-language></num-language>
    <mat-form-field class="language-select" style="width: auto">
      <mat-select
        (selectionChange)="logout()"
        [placeholder]="profile?.info.name ? profile?.info.name : profile?.info.preferred_username"
      >
        <mat-option value="de">
          <fa-icon size="lg" [fixedWidth]="true" [icon]="'sign-out-alt'"></fa-icon>
          Logout
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-toolbar>
