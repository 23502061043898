<ng-container *ngTemplateOutlet="tree; context: { node: treeData, level: 0 }"> </ng-container>

<ng-template #tree let-node="node" let-level="level">
  <div class="divider" [style.marginLeft]="calcMarginLeft(level)">
    <div fxLayout="row" fxLayoutAlign="start center" style="height: 50px" class="width-100">
      <div>
        <mat-checkbox
          *ngIf="node.data.selectable"
          [disabled]="!node.data.isDisabled"
          [checked]="node?.data.isCheckboxSelected"
          (change)="checkboxSelected(node)"
          [style.marginLeft]="calcMarginLeftCheckbox(level)"
        ></mat-checkbox>
        <div *ngIf="!node.data.selectable" class="checkbox-placeholder"></div>
      </div>
      <div
        *ngIf="node?.children && node.children.length > 0"
        class="tree-node"
        [style.marginLeft]="calcMarginLeftTreeNode(level, node.data.selectable)"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="toggleExpand(node)"
          class="icon-container"
        >
          <fa-icon *ngIf="!isExpanded(node)" icon="chevron-right"></fa-icon>
          <fa-icon *ngIf="isExpanded(node)" icon="chevron-down"></fa-icon>
        </div>
      </div>
      <div class="node-display-container width-80">
        <span
          (click)="checkboxSelected(node)"
          class="data-display"
          matTooltipPosition="after"
          matTooltipShowDelay="300"
          [matTooltip]="node.data.display | displayTranslation"
          >{{ node.data.display | displayTranslation }}</span
        >
        <div *ngIf="node.data.description" class="field-description">
          <span class="description-text" [matTooltip]="node.data.description | displayTranslation">
            ({{ node.data.description | displayTranslation }})
          </span>
        </div>
        <div
          *ngIf="node.data.displayFieldsInfo?.getOriginal().length > 0"
          class="field-description"
        >
          <span class="description-text" [matTooltip]="node.data.description | displayTranslation">
            ({{ node.data.displayFieldsInfo | displayTranslation }})
          </span>
        </div>
      </div>
    </div>
  </div>
  <ul *ngIf="isExpanded(node) && node.children && node.children.length > 0">
    <ng-container *ngFor="let child of node.children">
      <li>
        <ng-container
          *ngTemplateOutlet="tree; context: { node: child, level: level + 1 }"
        ></ng-container>
      </li>
    </ng-container>
  </ul>
</ng-template>
