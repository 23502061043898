<div *ngIf="filterChips.length > 0">
  <div *ngFor="let filterChip of filterChips" fxLayout="row" fxLayoutAlign="start start">
    <div *ngIf="filterChip.type === 'optional'" class="block">
      {{ 'DATASELECTION.SELECTION_BOX.OPTIONAL' | translate }}
    </div>
    <div *ngIf="filterChip.type === 'required'" class="block">
      {{ 'DATASELECTION.SELECTION_BOX.REQUIRED' | translate }}
    </div>
    <div
      *ngIf="filterChip.type !== 'required' && filterChip.type !== 'optional'"
      class="block"
      [ngClass]="{ expanded: filterChip.typeExpanded }"
      (click)="toggleTypeExpanded(filterChip)"
    >
      {{ filterChip.type }}
    </div>
    <div class="block-triangle"></div>
    <div fxLayout="row wrap" class="padding-left-20">
      <div
        *ngFor="let chip of filterChip.data"
        class="chip-container"
        [ngClass]="{ expanded: chip.expanded }"
        (click)="toggleExpanded(chip)"
      >
        {{ chip.text | displayTranslation }}
      </div>
    </div>
  </div>
</div>
