<mat-form-field appearance="fill" style="padding-right: 20px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-select
      disableOptionCentering
      (selectionChange)="onSelectionChange()"
      panelClass="example-panel"
      [placeholder]="'SHAREDCOMPONENTS.FILTER.' + filter?.filterType | translate"
      [(value)]="selectedValues"
      multiple
    >
      <mat-option *ngFor="let value of filter.data" [value]="value.label">
        {{ value.display }} ({{ value.count }})
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
