<div style="height: 100%">
  <num-concept
    *ngIf="attributeFilter.isConceptSet()"
    [attributeCode]="attributeFilter.getAttributeCode()"
    [conceptFilter]="attributeFilter.getConcept()"
    (changedConceptFilter)="updateConceptFilter($event)"
  ></num-concept>
  <num-quantity
    *ngIf="attributeFilter.getQuantity()"
    (quantityFilterChange)="updateQuantityFilter($event)"
    [quantityFilter]="attributeFilter.getQuantity()"
  ></num-quantity>
</div>
