<div *ngIf="(arrayOfSelectedConcepts$ | async).length > 0" class="height-100">
  <div class="filter-title">{{ 'SHAREDFILTER.FILTER_SELECTION' | translate }}</div>
  <div class="selected-filter-box">
    <div
      *ngFor="let selectedConceptFilter of arrayOfSelectedConcepts$ | async"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="selected-filter-element"
    >
      <div fxFlex="60" class="display-part">
        <span matTooltipPosition="below" [matTooltip]="selectedConceptFilter.display">{{ selectedConceptFilter.display }}</span>
      </div>
      <div fxFlex="30" class="display-part">
        {{ selectedConceptFilter.code }}
      </div>
      <div fxFlexAlign="end">
        <button (click)="removeSelectedConcept(selectedConceptFilter)" class="delete-button">
          {{ 'SHAREDFILTER.BUTTON.REMOVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
