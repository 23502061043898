<div
  *ngIf="isVisible"
  fxLayout="row"
  fxLayoutAlign="start center"
  [ngClass]="{ 'error-snackbar': color === 'red', 'info-snackbar': color === 'green' }"
  class="snackbar"
>
  <div fxFlex="95" class="message-display">{{ message | translate }}</div>
  <div fxFlex="5">
    <fa-icon (click)="closeSnackbar()" class="close-icon" size="lg" icon="times"> </fa-icon>
  </div>
</div>
