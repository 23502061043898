<num-table
  *ngIf="adaptedData?.body.rows.length > 0"
  class="result-list-container"
  [tableData]="adaptedData"
  (selectedRow)="addSelectedRow($event)"
>
</num-table>
<num-placeholder-box *ngIf="adaptedData?.body.rows.length <= 0" class="no-results-text">
  {{ 'SHAREDFILTER.EMPTY' | translate }} "<strong>{{ searchText$ | async }}</strong
  >"</num-placeholder-box
>
<num-button (click)="addSelectedConceptsToStage()" type="blue" class="add-button">
  {{ 'SHAREDFILTER.BUTTON.ADD' | translate }}
</num-button>
