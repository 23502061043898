<div class="container">
  <div fxLayout="column" class="content-container">
    <ng-content></ng-content>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <num-button type="blue" class="select-button" [isDisabled]="disabled" (click)="save()">
      {{ 'SHAREDCOMPONENTS.MODAL.BUTTON.APPLY' | translate }}
    </num-button>
    <num-button type="blue" class="select-button" (click)="cancel()">
      {{ 'SHAREDCOMPONENTS.MODAL.BUTTON.CANCEL' | translate }}
    </num-button>
  </div>
</div>
