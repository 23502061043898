<ng-container *ngIf="listItemDetails$ | async as listItemDetails">
  <mat-list class="border-top">
    <!-- Parents -->
    <ng-container>
      <div fxLayout="row" fxLayoutAlign="start start" class="padding-20 divider">
        <div fxLayout="row" fxLayoutAlign="start center" class="section-layout">
          {{ 'SHAREDCOMPONENTS.LIST_ITEM_DETAILS.PARENTS' | translate }}
        </div>
        <div style="width: 100%">
          <num-list-item-details-sections [listItemDetails]="listItemDetails.parents">
          </num-list-item-details-sections>
        </div>
      </div>
    </ng-container>
    <!-- Children -->
    <ng-container>
      <div fxLayout="row" fxLayoutAlign="start start" class="padding-20 padding-top-5 divider">
        <div class="section-layout">
          {{ 'SHAREDCOMPONENTS.LIST_ITEM_DETAILS.CHILDREN' | translate }}
        </div>
        <div style="width: 100%">
          <num-list-item-details-sections
            [listItemDetails]="listItemDetails.children"
            (selectedRelative)="getSelectedRelative($event)"
          >
          </num-list-item-details-sections>
        </div>
      </div>
    </ng-container>
  </mat-list>
</ng-container>
