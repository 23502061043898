<num-modal-window (saveButtonSelected)="saveCriterion()" (cancelButtonSelected)="closeDialog()">
  <div
    *ngFor="let attributeFilter of criterion.getAttributeFilters()"
    [ngClass]="{ 'full-height': attributeFilter.getFilterType() !== 'reference' }"
  >
    <num-attribute-filter
      [attributeFilter]="attributeFilter"
      (attributeFilterChange)="updateAttributeFilter($event)"
    >
    </num-attribute-filter>
  </div>
  <div *ngFor="let valueFilter of criterion.getValueFilters()" class="full-height">
    <num-value-filter [valueFilter]="valueFilter" (valueFilterChange)="updateValueFilter($event)">
    </num-value-filter>
  </div>
  <num-edit-time-restriction
    *ngIf="criterion.getTimeRestriction()"
    [timeRestriction]="criterion.getTimeRestriction()"
    (timeRestrictionChanged)="updateTimeRestriction($event)"
  >
  </num-edit-time-restriction>
</num-modal-window>
