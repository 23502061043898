<mat-nav-list fxLayout="column" fxFill>
  <ng-template ngFor let-item [ngForOf]="mainNavItems">
    <ng-template [ngIf]="showPage(item.routeTo)">
      <a
        *numUserHasRole="item.roles"
        class="num-mat-list-item"
        [routerLinkActive]="'num-mat-list-item--active'"
        mat-list-item
        [routerLink]="item.routeTo"
        (click)="menuItemClicked($event, item)"
      >
        <fa-icon size="lg" [fixedWidth]="true" [icon]="item.icon"></fa-icon>
        <div *ngIf="isSideMenuExpanded">{{ item.translationKey | translate }}</div>
      </a>
    </ng-template>
  </ng-template>

  <span fxFlex></span>
  <div fxLayout="row" fxLayoutAlign="end center" class="num-logo-row">
    <button
      type="button"
      [attr.aria-label]="'APPLAYOUT.TOGGLE.LABEL' | translate"
      mat-icon-button
      (click)="menuItemClicked($event)"
    >
      <fa-icon *ngIf="isSideMenuExpanded" size="1x" icon="angle-double-left"></fa-icon>
      <fa-icon *ngIf="!isSideMenuExpanded" size="1x" icon="angle-double-right"></fa-icon>
    </button>
  </div>
</mat-nav-list>
