<div cdkDrag fxLayout="row" fxLayoutAlign="start start" class="container">
  <div class="content">
    <div matTooltipPosition="below" [matTooltip]="referenceCriterion.getTermCodes()[0].getDisplay()" class="title-section">
      {{ referenceCriterion.getTermCodes()[0].getDisplay() }}
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" class="info-section">
      <div class="info-item">
        {{ referenceCriterion.getContext().getDisplay() }},
        {{ referenceCriterion.getTermCodes()[0].getCode() }},
        {{ translatedSystem }}
      </div>
    </div>
    <div class="filter-chips-container">
      <num-filter-chips [filterChips]="$filterChips | async"></num-filter-chips>
    </div>
  </div>
  <div class="handle-icons" fxLayout="column" fxLayoutAlign="start start">
    <num-menu [menuItems]="menuItems" [id]="referenceCriterion.getId()"></num-menu>
  </div>
</div>
