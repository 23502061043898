<div fxLayout="column" class="container">
  <div class="attribute-code-display"></div>
  <num-search-concept [valueSetUrl]="valueSetUrl" [conceptFilterId]="conceptFilterId">
  </num-search-concept>
  <num-concept-filter-table
    *ngIf="searchResults$ | async as searchResults"
    [valueSetUrl]="valueSetUrl"
    [conceptFilterId]="conceptFilterId"
  ></num-concept-filter-table>
  <num-staged-concepts></num-staged-concepts>
</div>
