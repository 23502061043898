<div
  cdkDrag
  [cdkDragData]="criterion.getId()"
  fxLayout="row"
  fxLayoutAlign="start start"
  class="container"
>
  <div class="content">
    <div
      matTooltipPosition="below"
      [matTooltip]="criterion.getTermCodes()[0].getDisplay()"
      class="title-section"
    >
      {{ criterion.getTermCodes()[0].getDisplay() }}
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" class="info-section">
      <div class="info-item">
        {{ criterion.getContext().getDisplay() }}, {{ criterion.getTermCodes()[0].getCode() }},
        {{ system }}
      </div>
    </div>
    <div class="filter-chips-container">
      <div
        *ngIf="isFilterRequired"
        fxLayout="row"
        fxLayoutAlign="start center"
        style="margin-bottom: 5px"
      >
        <img src="assets/img/alert-blue-white.png" class="img-layout" />
        <span class="warning-message">
          {{ 'SHAREDCOMPONENTS.CRITERIA_BOX.REQUIRED_FILTER' | translate }}
        </span>
      </div>

      <num-filter-chips [filterChips]="$filterChips | async"></num-filter-chips>
    </div>
  </div>
  <div class="handle-icons" fxLayout="column" fxLayoutAlign="start start">
    <num-menu *ngIf="isEditable" [menuItems]="menuItems" [id]="criterion.getId()"></num-menu>
    <div *ngIf="isEditable" fxFlexAlign="end" class="menu-icon" cdkDragHandle>
      <fa-icon class="icon drag-handle" size="m" icon="expand-arrows-alt"></fa-icon>
    </div>
  </div>
</div>
<div style="padding-left: 50px" *ngFor="let attributeFilter of criterion.getAttributeFilters()">
  <div *ngIf="attributeFilter?.isReferenceSet()">
    <div *ngFor="let reference of attributeFilter.getReference().getSelectedReferences()">
      <num-reference-criteria-box
        [criterionId]="criterion.getId()"
        [referenceCriterion]="reference"
      ></num-reference-criteria-box>
    </div>
  </div>
</div>
