<div class="container" *ngIf="quantityFilter">
  <mat-expansion-panel togglePosition="before" [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'QUERYBUILDER.EDIT.VALUE' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayoutAlign="start center">
      <!-- Comparator Select-->
      <num-quantity-comparision-select
        [existingOption]="quantityFilter.getComparator()"
        (selectedOption)="setSelectedQuantityFilterOption($event)"
      >
      </num-quantity-comparision-select>

      <!-- Quantity Range-->
      <ng-container *ngIf="isBetweenFilter">
        <num-quantity-range
          [minValue]="quantityRangeFilter?.getMinValue()"
          [maxValue]="quantityRangeFilter?.getMaxValue()"
          [quantityFilterUnit]="selectedQuantityFilterUnit"
          (quantityRangeInstance)="emitQuantityFilterInstance($event)"
        >
        </num-quantity-range>
      </ng-container>

      <!-- Quantity Comparator-->
      <ng-container *ngIf="isComparatorFilter">
        <num-quantity-comparator
          [value]="quantityComparatorFilter?.getValue()"
          [quantityFilterUnit]="selectedQuantityFilterUnit"
          [quantityComparatorType]="selectedQuantityFilterComparator"
          (quantityComparatorInstance)="emitQuantityFilterInstance($event)"
        >
        </num-quantity-comparator>
      </ng-container>

      <!-- Quantity Units-->
      <ng-container *ngIf="!isQuantityNotSet">
        <num-allowed-units
          [selectedUnit]="
            quantityFilter.getSelectedUnit()
              ? quantityFilter.getSelectedUnit()
              : quantityFilter.getAllowedUnits()[0]
          "
          [allowedUnits]="quantityFilter.getAllowedUnits()"
          (selectionChange)="setSelectQuantityFilterUnit($event)"
        >
        </num-allowed-units>
      </ng-container>
    </div>
  </mat-expansion-panel>
</div>
