<mat-drawer-container fxFlexFill>
  <mat-drawer #drawer position="end" mode="side">
    <div fxLayout="column" *ngIf="data" class="padding-top-50">
      <button class="cancel-button" fxLayoutAlign="center end" (click)="drawer.toggle()">
        Back to list
        <fa-icon
          class="close-icon"
          [attr.aria-label]="'BUTTON.CANCEL' | translate"
          icon="times"
          (click)="handleDialogClose()"
          [fixedWidth]="true"
        ></fa-icon>
      </button>
      <num-list-item-details fxFlexFill [item]="data" class="padding-left-2">
      </num-list-item-details>
    </div>
  </mat-drawer>
  <mat-drawer-content *ngIf="searchTermListItems.length > 0" class="content-height-95">
    <ng-container *ngIf="searchTermListItems.length > 0">
      <num-result-list
        [searchTermResultList]="searchTermListItems"
        [keysToSkip]="keysToSkip"
        fxFlexFill
      ></num-result-list>
    </ng-container>
  </mat-drawer-content>
</mat-drawer-container>
