<fa-icon
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  class="menu-icon"
  size="1x"
  icon="pencil-alt"
>
</fa-icon>
<mat-menu #menu="matMenu" yPosition="below" xPosition="before">
  <div class="divider options">{{ 'SHAREDCOMPONENTS.MENU.OPTIONS' | translate }}</div>
  <div *ngFor="let item of menuItems; let isLast = last">
    <button
      [ngClass]="{ divider: !isLast, 'last-item': isLast }"
      mat-menu-item
      [disabled]="item.disabled"
      (click)="item.action(id)"
    >
      <fa-icon [icon]="item.icon" size="sm" class="icon-color"></fa-icon>
      {{ 'SHAREDCOMPONENTS.MENU.' + item.label | translate }}
    </button>
  </div>
</mat-menu>
