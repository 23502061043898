<div class="container" *ngIf="savedQuery">
  <div fxLayout="column" class="text-layout width-50">
    <div class="label text-overflow">
      <span matTooltipPosition="below" [matTooltip]="savedQuery.label">
        {{ savedQuery.label }}
      </span>
    </div>
    <div *ngIf="savedQuery.comment" class="comment text-overflow">
      <span matTooltipPosition="below" [matTooltip]="savedQuery.comment">
        {{ savedQuery.comment }}
      </span>
    </div>
  </div>
  <div class="width-10">
    {{ ('SAVED_QUERIES.CREATED_AT' | translate) + ' ' + (savedQuery.date | date : 'dd.MM.yyyy') }}
  </div>
  <div class="width-20">
    {{ 'QUERYBUILDER.OVERVIEW.COHORT.PATIENTS' | translate }} {{ savedQuery.totalNumberOfPatients }}
  </div>
  <div>
    <num-button type="blue" (click)="onNavigate(savedQuery?.id)">
      {{ 'QUERYBUILDER.OVERVIEW.COHORT.LOAD' | translate }}
    </num-button>
  </div>
  <div class="height-100" fxFlex="row" fxLayoutAlign="end start" fxFlex="4">
    <fa-icon
      class="pointer close-icon"
      icon="times"
      (click)="onDelete(savedQuery.id)"
      [fixedWidth]="true"
    ></fa-icon>
  </div>
</div>
