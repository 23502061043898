<div fxLayout="column" class="container">
  <div class="attribute-code-display">{{ attributeCode.getDisplay() }}</div>
  <num-searchbar (searchTextChange)="startElasticSearch($event)"> </num-searchbar>
  <num-table
    *ngIf="listItems.length > 0 && adaptedData"
    class="result-list-container"
    [tableData]="adaptedData"
    [searchTermResultList]="listItems"
    (selectedRow)="setSelectedRowItem($event)"
  ></num-table>
  <num-placeholder-box class="no-results-text" *ngIf="!searchResultsFound"
    >{{ 'QUERYBUILDER.SEARCH.EMPTY' | translate }} "<strong>{{ searchText$ | async }}</strong
    >"</num-placeholder-box
  >
  <button
    *ngIf="isTableItemsSelected"
    mat-raised-button
    class="add-button"
    color="white"
    (click)="setSelectedReferenceCriteria()"
  >
    {{ 'SHAREDFILTER.BUTTON.ADD' | translate }}
  </button>
  <div *ngIf="arrayOfSelectedReferences.length > 0" class="filter-selection">
    <div class="filter-title">Filter selection</div>
    <div class="selectedFilterBox">
      <div
        *ngFor="let selectedReferences of arrayOfSelectedReferences; let index = index"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="selectedFilter"
      >
        <div
          matTooltipPosition="below"
          matTooltipShowDelay="300"
          [matTooltip]="selectedReferences.termCode.getDisplay()"
          fxFlex="50"
          class="display-part"
        >
          {{ selectedReferences.termCode.getDisplay() }}
        </div>
        <div fxFlex="50" class="display-part">
          {{ selectedReferences.termCode.getCode() }} ({{
            selectedReferences.termCode.getSystem()
          }})
        </div>
        <div fxFlexAlign="end">
          <button (click)="removeSelectedReference(index)" class="delete-button">entfernen</button>
        </div>
      </div>
    </div>
  </div>
</div>
