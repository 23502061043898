<div class="layout" style="overflow: hidden">
  <div
    fxFill
    fxLayout="row"
    fxLayoutAlign="end center"
    class="padding-left-right-5px content-container"
  >
    <ng-content></ng-content>
  </div>
</div>
