<mat-form-field class="language-select">
  <span *ngIf="translate.currentLang === 'de'" class="fi fi-de" matPrefix></span>
  <span *ngIf="translate.currentLang === 'en'" class="fi fi-gb" matPrefix></span>

  <mat-select
    hideSingleSelectionIndicator
    [placeholder]="
      translate.currentLang === 'de'
        ? ('LANGUAGE.GERMAN' | translate)
        : ('LANGUAGE.ENGLISH' | translate)
    "
    (selectionChange)="changeLanguage($event.value)"
  >
    <mat-option value="de">
      <span style="margin-right: 10px" class="fi fi-de option-layout"></span>
      {{ 'LANGUAGE.GERMAN' | translate }}
    </mat-option>
    <mat-option value="en">
      <span style="margin-right: 10px" class="fi fi-gb option-layout"></span>
      {{ 'LANGUAGE.ENGLISH' | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
